import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { Card, Pagination, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./imageview.css";

const Deposits = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const fetchTransactions = useCallback((page = 1) => {
    setIsLoading(true);
    setError(null);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    axios.get(`${baseUrl}deposit/pending?page=${page}`, { headers })
      .then((res) => {
        setTransactions(res.data.transactions);
        setCurrentPage(res.data.currentPage);
        setTotalPages(res.data.totalPages);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
        setError("Failed to fetch transactions. Please try again.");
        setIsLoading(false);
      });
  }, [baseUrl]);

  const update = useCallback(async (id) => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`
      };
      await axios.post(`${baseUrl}/deposit/manual`,
        { status: "success", id: id },
        { headers }
      );
      fetchTransactions(currentPage);
    } catch (e) {
      console.error("Error updating transaction:", e);
      setError("Failed to update transaction. Please try again.");
    }
  }, [baseUrl, currentPage, fetchTransactions]);

  const cancelledData = useCallback(async (id) => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`
      };
      await axios.delete(`${baseUrl}/deposit/delete/${id}`, { headers });
      fetchTransactions(currentPage);
    } catch (e) {
      console.error("Error deleting transaction:", e);
      setError("Failed to delete transaction. Please try again.");
    }
  }, [baseUrl, currentPage, fetchTransactions]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const handlePageChange = (pageNumber) => {
    fetchTransactions(pageNumber);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Deposit Request</h4>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>ID</th>
                    <th>Amount</th>
                    <th>Request Type</th>
                    <th>Reference ID</th>
                    <th>Screenshot</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td>{item._id}</td>
                      <td>{item.amount}</td>
                      <td>{item.Req_type}</td>
                      <td>{item.referenceId}</td>
                      <td>
                        <div className="img-panel">
                          <img
                            className="img"
                            src={`${baseUrl}${item.screenshotPath}`}
                            alt="Screenshot"
                            style={{
                              borderRadius: '5px',
                              width: '4rem',
                              height: '4rem',
                            }}
                          />
                        </div>
                      </td>
                      <td>{item.status}</td>
                      <td>
                        {item.status === "Pending" && (
                          <>
                            <button className="btn btn-primary mr-2" onClick={() => update(item._id)}>
                              Approve
                            </button>
                            <button className="btn btn-danger mr-2" onClick={() => cancelledData(item._id)}>
                              Delete
                            </button>
                          </>
                        )}
                        {(item.status === "success" || item.status === "PAID") && (
                          <button className="btn btn-success mr-2">Success</button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {totalPages > 1 && (
              <div className="d-flex justify-content-center mt-4">
                <Pagination>
                  <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                  <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                  {[...Array(totalPages).keys()].map((number) => (
                    <Pagination.Item
                      key={number + 1}
                      active={number + 1 === currentPage}
                      onClick={() => handlePageChange(number + 1)}
                    >
                      {number + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                  <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                </Pagination>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deposits;
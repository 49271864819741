import React, { useState, useEffect } from "react";
import axios from "axios";

const Gateway = () => {
  const [RazorPayout, setRazorpayout] = useState(true);
  const [RazorDeposit, setRazorDeposit] = useState(true);
  const [RazorpayAuto, setRazorpayAuto] = useState(false);
  const [RazorPayKey, setRazorpayKey] = useState();
  const [RazorPaySecretKey, setRazorpaysecretKey] = useState();
  const [AccountName, setAccountName] = useState();
  const [Upi, setUpi] = useState();
  const [Image, setImage] = useState(null); // State for the image file
  const [decentroPayout, setdecentropayout] = useState(true);
  const [decentroDeposit, setdecentroDeposit] = useState(true);
  const [decentroAuto, setdecentroAuto] = useState(false);
  const [settingId, setSettingId] = useState("");

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  useEffect(() => {
    axios.get(baseUrl + "gatewaysettings/data").then((res) => {
      console.log(res.data);
      setSettingId(res.data._id || '');
      setRazorpayout(res.data.RazorPayout);
      setRazorDeposit(res.data.RazorDeposit);
      setRazorpayAuto(res.data.RazorpayAuto);
      setdecentropayout(res.data.decentroPayout);
      setdecentroDeposit(res.data.decentroDeposit);
      setdecentroAuto(res.data.decentroAuto);
      setRazorpayKey(res.data.RazorPayKey);
      setRazorpaysecretKey(res.data.RazorPaySecretKey);
      setAccountName(res.data.AccountName);
      setUpi(res.data.upi);
    });
  }, []);

  const handleFileChange = (e) => {
    setImage(e.target.files[0]); // Set the selected file
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("settingId", settingId);
    formData.append("RazorPayout", RazorPayout);
    formData.append("RazorDeposit", RazorDeposit);
    formData.append("RazorpayAuto", RazorpayAuto);
    formData.append("decentroPayout", decentroPayout);
    formData.append("decentroDeposit", decentroDeposit);
    formData.append("decentroAuto", decentroAuto);
    formData.append("RazorPayKey", RazorPayKey);
    formData.append("RazorPaySecretKey", RazorPaySecretKey);
    formData.append("AccountName", AccountName);
    formData.append("Upi", Upi);
    if (Image) {
      formData.append("Image", Image); // Append the image file if present
    }

    try {
      const response = await axios.post(baseUrl + "gatewaysettings", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data.status);
      if (response.data.status === 'success') {
        alert("Settings submitted successfully");
      } else {
        alert("Settings Not Submitted");
      }
    } catch (error) {
      console.error("There was an error submitting the settings!", error);
      alert("Error submitting settings");
    }
  };

  return (
    <>
      <h4 className="text-uppercase font-weight-bold my-3">
        Payment Gateway Settings
      </h4>

      <h5 className="text-uppercase font-weight-bold my-3">RazorPay</h5>

      <form
        className="form"
        onSubmit={handleSubmit1}
      >
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazpay" className="col-2 my-1">
              Razorpay Payout
            </label>
            <select
              className="form-control "
              value={RazorPayout}
              onChange={(e) => setRazorpayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttonrazdep" className="col-2 my-1">
              Razorpay Deposit
            </label>
            <select
              className="form-control "
              value={RazorDeposit}
              onChange={(e) => setRazorDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttonrazauto" className="col-2 my-1">
              RazorPay Auto
            </label>
            <select
              className="form-control"
              value={RazorpayAuto}
              onChange={(e) => setRazorpayAuto(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="RazorpayKey">RazorPay Key</label>
            <input
              className="form-control"
              type="text"
              value={RazorPayKey}
              onChange={(e) => setRazorpayKey(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="RazorpaysecretKey">RazorPay SecretKey</label>
            <input
              className="form-control"
              type="text"
              value={RazorPaySecretKey}
              onChange={(e) => setRazorpaysecretKey(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="AccountName">Account Name</label>
            <input
              className="form-control"
              type="text"
              value={AccountName}
              onChange={(e) => setAccountName(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="Upi">Upi</label>
            <input
              className="form-control"
              type="text"
              value={Upi}
              onChange={(e) => setUpi(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="Image">Upload UPI Image</label>
            <input
              type="file"
              className="form-control"
              onChange={handleFileChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <button type="submit" className="btn btn-dark">
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Gateway;